const DEFAULT_PIPEDRIVE_TYPE = 'rcd rich';

const PIPEDRIVE_FIELDS = {
  civility: 'cfbc92c0a5f87212cff0c11b89b60b0a23c768d9',
  name: 'last_name',
  prenom: 'first_name',
  codepostal: 'adeabfafae61e2330e2178bfc572d9e1f94d585c',
  pipedrive_futur_projet: '59273eac6932de4d065b7751b135d1db74eece39',
  pipedrive_a_maitre: '20a9ea11d0205ff721c1191436788a8725be2257',
  pipedrive_cout_estime: '822ce2afc060cafc4c7467d9eb36f9d25562fa46',
  rcd_activites_exercees: '4e94642690100ead053322b90df4275cf7ee2c30',
  rcd_activite_principale: 'f5d3658033b478d195928d90c086d80302c39f6c',
  rcd_chiffre_affaires: '776c689a0d5c9d847bde4a2f22b3700ae96449ae',
  rc_decennale: '05b35eac998d5502ae8d443623610303ac32f8ad',
  //NEW FIELDS
  rcd_qualipv_certification: '6e51233eff4359cd361fb07ebfcef121f9bc9363',
  rcd_a_experience: 'a8114785736737db0f32338000e1bffba323dd50',
  rcd_dannee_experience: '6f182bd2fd47e8d9d216840a344d72838879006a',
  //FINISH_NEW_FIELDS
  rcd_date_creation: '5208727cdebc8c90d1d8c4d96e06114ed4c768a8',
  rcd_code_postal: 'cc8d855728f417aafc6cf27fd45a478c41fbe956',

  email: 'email',
  phone: 'phone',
  term: 'term',
  exact_match: 'exact_match',
  full_name: 'name',
  //HIDDEN FIELDS
  pipedrive_pipeline_id: 'pipeline_id',
  pipedrive_stage_id: 'stage_id',
  pipedrive_user_id: 'user_id',
  pipedrive_provenance: '3426de3d6b4af48886f8aefa0f066cd094b03ef0',
  pipedrive_vecteur: '6c2d09d3ce4693f3c7a3686cda168acdacda4293',
  pipedrive_typologie_produit: 'ab6b0b4f969c04d14fff489eeeb39f99106c888d',
  //DYNAMIC FIELDS
  person_id: 'person_id',
  cmpid: '9f2d8273cc8ea8e091298e989ac9842e039af649',
  gclid: 'e19de2394690ccb59865b0d34024c51e0d3f7b70',
  prescripteurid: '0d344e3b66fda56320e41b0c09914d9263ea49b3',
  referral: 'de400316a95b6e51d27d7aa8c27e270b0691d638',
  title: 'title',
  //LOCAL FIELD
  owner_id: 'owner_id',
  pipedrive_type: 'pipedrive_type',
};

const TYPOLOGIE_PRODUIT = {
  'rcd rich': 69,
  'rcd simple': 69,
  do: 70,
  auto: 71,
};

const exact_match = true;
const path = '/api/pipedrive/persons';

const addPipedriveUser = async (formEntries) => {
  const term = formEntries.email;
  const params = new URLSearchParams({
    [PIPEDRIVE_FIELDS.term]: term,
    [PIPEDRIVE_FIELDS.exact_match]: exact_match.toString(),
  });
  const pathWithQuery = `${path}?${params}`;

  const body = {
    [PIPEDRIVE_FIELDS.owner_id]: formEntries.pipedrive_user_id,
    [PIPEDRIVE_FIELDS.full_name]: `${formEntries.firstname} ${formEntries.name}`,
    [PIPEDRIVE_FIELDS.civility]: formEntries.civility,
    [PIPEDRIVE_FIELDS.name]: formEntries.name,
    [PIPEDRIVE_FIELDS.prenom]: formEntries.firstname,
    [PIPEDRIVE_FIELDS.email]: formEntries.email,
    [PIPEDRIVE_FIELDS.phone]: formEntries.phone,
  };

  try {
    const response = await useFetch(pathWithQuery, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.status.value === 'success') {
      return await response.data.value;
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
};

const getPipedriveUser = async (email) => {
  const term = email;

  const params = new URLSearchParams({
    [PIPEDRIVE_FIELDS.term]: term,
    [PIPEDRIVE_FIELDS.exact_match]: exact_match.toString(),
  });

  const pathWithQuery = `${path}?${params}`;

  try {
    const response = await useFetch(pathWithQuery, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status.value === 'success') {
      return await response.data.value;
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
};

const fetchPipedriveUser = async (formEntries) => {
  const result = await getPipedriveUser(formEntries.email);

  const isUserFound = result.data.items.length > 0;

  const foundUser = isUserFound
    ? result
    : await getPipedriveUser(formEntries.phone);

  if (foundUser.data.items.length > 0) {
    const user = foundUser.data.items[0];
    return {
      owner_id: user.item.owner.id,
      id: user.item.id,
    };
  }

  const newUser = await addPipedriveUser(formEntries);

  return {
    owner_id: newUser.data.owner_id.id,
    id: newUser.data.id,
  };
};

const listURLIDs = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cmpid = urlParams.get('cmpid');
  const gclid = urlParams.get('gclid');
  const prescripteurid = urlParams.get('pprid');
  const referral = window.document.referrer || null;
  return {
    cmpid,
    gclid,
    prescripteurid,
    referral,
  };
};

export async function pipedriveAdapter(formData) {
  const formEntries = {};
  for (const [key, value] of formData.entries()) {
    formEntries[key] = value;
  }

  const pipedriveType = formEntries.pipedrive_type || DEFAULT_PIPEDRIVE_TYPE;
  const typologie_produit = TYPOLOGIE_PRODUIT[pipedriveType];

  const urlIds = listURLIDs();

  const pipedriveUser = await fetchPipedriveUser(formEntries);

  const isRCD = ['rcd rich', 'rcd simple'].includes(pipedriveType);
  const rcd_fields = !isRCD
    ? {}
    : {
        [PIPEDRIVE_FIELDS.rcd_activites_exercees]:
          formEntries?.rcd_activites_exercees,
        [PIPEDRIVE_FIELDS.rcd_chiffre_affaires]:
          formEntries?.rcd_chiffre_affaires?.replace(/\s/g, ''),
        [PIPEDRIVE_FIELDS.rc_decennale]: formEntries?.rc_decennale,
        [PIPEDRIVE_FIELDS.rcd_qualipv_certification]:
          formEntries?.rcd_qualipv_certification,
        [PIPEDRIVE_FIELDS.rcd_a_experience]: formEntries?.rcd_a_experience,
        [PIPEDRIVE_FIELDS.rcd_dannee_experience]:
          formEntries?.rcd_dannee_experience,
        [PIPEDRIVE_FIELDS.rcd_date_creation]: formEntries?.rcd_date_creation,
        [PIPEDRIVE_FIELDS.rcd_code_postal]: formEntries?.rcd_code_postal,
        [PIPEDRIVE_FIELDS.rcd_activite_principale]:
          formEntries?.rcd_activite_principale,
      };

  const do_fields =
    pipedriveType !== 'do'
      ? {}
      : {
          [PIPEDRIVE_FIELDS.pipedrive_futur_projet]:
            formEntries.pipedrive_futur_projet,
          [PIPEDRIVE_FIELDS.pipedrive_a_maitre]: formEntries.pipedrive_a_maitre,
          [PIPEDRIVE_FIELDS.pipedrive_cout_estime]:
            formEntries.pipedrive_cout_estime,
        };

  const deal = {
    [PIPEDRIVE_FIELDS.codepostal]: formEntries?.postalcode,
    [PIPEDRIVE_FIELDS.pipedrive_pipeline_id]:
      formEntries?.pipedrive_pipeline_id,
    [PIPEDRIVE_FIELDS.pipedrive_stage_id]: formEntries?.pipedrive_stage_id,
    [PIPEDRIVE_FIELDS.pipedrive_user_id]: formEntries?.pipedrive_user_id,
    [PIPEDRIVE_FIELDS.pipedrive_provenance]: formEntries?.pipedrive_provenance,
    [PIPEDRIVE_FIELDS.pipedrive_vecteur]: formEntries?.pipedrive_vecteur,
    [PIPEDRIVE_FIELDS.pipedrive_typologie_produit]: typologie_produit,
    [PIPEDRIVE_FIELDS.person_id]: pipedriveUser?.id,
    [PIPEDRIVE_FIELDS.cmpid]: urlIds.cmpid,
    [PIPEDRIVE_FIELDS.gclid]: urlIds.gclid,
    [PIPEDRIVE_FIELDS.prescripteurid]: urlIds.prescripteurid,
    [PIPEDRIVE_FIELDS.referral]: urlIds.referral,
    [PIPEDRIVE_FIELDS.title]: `${formEntries?.firstname} ${formEntries?.name}`,
    ...rcd_fields,
    ...do_fields,
  };

  return deal;
}
