import jsesc from 'jsesc';
import { toPascalCase } from '~/utils/case-transform';

const parseInput = (key, value) => {
  if (!key || !value) {
    return null;
  }

  switch (key) {
    case 'euroAmount':
      return value.replace(/\s/g, '');
    case 'civility':
      if (['M.', '1'].includes(value)) {
        return 'Mr';
      } else if (['Mlle', '2', '3'].includes(value)) {
        return 'Mme';
      } else {
        return value;
      }
    case 'date':
      return value.split('/').reverse().join('-');
    default:
      return value;
  }
};

export default ({ formData, origin, withSteps = false, stepsExtra }) => {
  const context = useNuxtApp();
  const route = useRoute();
  const api = '/api/marketplace';

  if (withSteps && stepsExtra) {
    Object.keys(stepsExtra).forEach((extraKey) => {
      formData.append(toPascalCase(extraKey), stepsExtra[extraKey]);
    });
  }

  return new Promise((resolve, reject) => {
    const market = formData.get('market');
    const marketProps =
      market === 'health'
        ? {
            healthProperties: {
              supplierProductId: formData.get('Produit'),
            },
            project: {
              market: formData.get('Marche'),
              provider: formData.get('Provider')?.toUpperCase(),
            },
          }
        : {
            borrowerProperties: {
              loans: [
                {
                  euroAmount:
                    parseInput('euroAmount', formData.get('euroAmount')) || 0,
                  duration: formData.get('duration'),
                  rate: formData.get('rate'),
                },
              ],
              insuredComplements: [
                {
                  smokingType: formData.get('smokingType') || 'NOT_SMOKING',
                  supplierProductId: formData.get('Produit'),
                },
              ],
            },
            project: {
              market: formData.get('Marche'),
              effectiveDate: parseInput('date', formData.get('effectiveDate')),
              provider: formData.get('Provider')?.toUpperCase(),
            },
          };

    const cmpid = context.$cookies.get('cmpid');
    const referrerCookie = context.$cookies.get('__april_ref');
    const lanCookie = context.$cookies.get('__april_lan');
    const gclid = route?.query?.gclid || null;
    const request = {
      Origine: origin,
      OrigineVisiteur: origin,
      lead: {
        pageOrigine: route.fullPath,
        siteInternetOrigine: origin,
      },
      broker: {
        gieCode: '25437',
        companyCode: '09999',
        company: 'APASS',
      },
      originContext: {
        vectorId: formData.get('Idvecteur'),
        campaignId: cmpid,
        referral: referrerCookie,
        gclId: gclid,
        landingPage: lanCookie,
      },
      insureds: [
        {
          civility: parseInput('civility', formData.get('civility')),
          roleRefId: formData.get('Rolerefid'),
          surname: formData.get('surname'),
          name: formData.get('name'),
          birthDate: parseInput('date', formData.get('birthDate')),
          email: formData.get('email'),
          phone: formData.get('phone'),
          address: {
            addressLine6: {
              zipCode: formData.get('zipCode'),
            },
            codeIsoCountry: formData.get('Isocountry'),
          },
        },
      ],
      ...marketProps,
    };

    const raw = jsesc(request, {
      json: true,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: raw,
    };

    useFetch(api, requestOptions)
      .then((response) => response.data.value)
      .then((response) => {
        if (response.orderId) {
          resolve({ ...response, formData });
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });
};
