import aesjs from 'aes-js';
import { ref } from 'vue';

export const trackReferral = async ({ voucher_code }, formData) => {
  const data = {
    order: {
      voucher_code,
      full_name: `${formData.get('prenomFilleul')} ${formData.get(
        'nomFilleul'
      )}`, // friend name
      email_address: formData.get('emailFilleul'), // friend email
      marketing_opt_in: 'true',
      order_number: formData.get('emailFilleul'), // friend email
      order_timestamp: Date.now(),
      locale: 'fr',
    },
  };

  try {
    const response = await useFetch('/api/buyapowa/referral', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.status.value === 'success') {
      return;
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
};

export const useBuyapowa = () => {
  const buyapowa = ref(null);
  const config = useRuntimeConfig();

  const router = useRouter();

  function decryptor(keyString, input, s) {
    const key = keyString.trim();
    let data;

    if (s) {
      const _s = s.trim();
      data = input.split(_s)[1].trim();
    } else {
      data = input.trim();
    }
    const keyBuffer = aesjs.utils.utf8.toBytes(key);
    const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
    const buf = Buffer.from(data, 'base64');
    const decryptedBytes = escEcb.decrypt(buf);
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

    const regex = /[\u{0001}-\u{0010}]/gu;

    const result = regex.test(decryptedText)
      ? decryptedText.replace(/[\u{0001}-\u{0010}]/gu, '')
      : decryptedText;
    return result.trim();
  }

  onMounted(() => {
    const password = config.public.buyapowa_aes_password;
    const encodedParameter = router.currentRoute?.query?.bp_e;
    if (!encodedParameter) {
      return;
    }
    const decrypt = decryptor(password, encodedParameter);
    const data = JSON.parse(decrypt);
    buyapowa.value = data;
  });

  return { buyapowa };
};
