import { azzurAdapter } from './adapter';

export default ({ formData, origin }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const cmpid = urlParams.get('cmpid');
  const gclid = urlParams.get('gclid');
  const referrer = window.document.referrer || null;

  const type = formData.get('type');
  const api = `/api/azzur/${type}`;

  return new Promise((resolve, reject) => {
    const data = {
      ws: 'mer',
      retour_ws_ok: 'success',
      retour_ws_nok: false,
      retour_ws_doublon: 'DOUBLON',
      retour_ws: 'json',
      ...azzurAdapter(formData, type),
    };

    switch (type) {
      case 'rcd':
        data.type = '12';
        data.cle_ws = '67f1c5a0d80f3638b88ffd53f4478e6e';
        data.rcd_ref = referrer;
        data.rcd_consent = 1;
        cmpid && (data.rcd_pmd = cmpid);
        gclid && (data.rcd_gclid = gclid);
        break;
      case 'auto':
        data.type = '14';
        data.cle_ws = '947755bbb872668eea74807c55965e18';
        data.aut_ref = referrer;
        data.aut_consent = 1;
        cmpid && (data.aut_pmd = cmpid);
        gclid && (data.aut_gclid = gclid);
        break;
      default:
        break;
    }

    const url = new URL(api, origin);
    url.search = new URLSearchParams(data);
    const proxyUrlWithParams = `${url.pathname}${url.search}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    useFetch(proxyUrlWithParams, requestOptions)
      .then((response) => {
        if (!response.status.value === 'success') {
          throw new Error(response.statusText);
        }

        return response.data.value;
      })
      .then((response) => {
        const successStrings = ['success', 'DOUBLON'];
        if (
          response.retour &&
          !successStrings.some((el) => response.retour.includes(el))
        ) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
};
