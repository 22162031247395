import jsesc from 'jsesc';

const specialInputs = {
  MontantPret1: {
    parse: (value) => value.replace(/\s/g, ''),
  },
};

export default ({ formData, origin }) => {
  const context = useNuxtApp();
  const route = useRoute();

  const api = '/api/devis';

  return new Promise((resolve, reject) => {
    const cmpid = context.$cookies.get('cmpid');

    const request = {
      Origine: cmpid || 'undefined',
      OrigineVisiteur: cmpid || 'undefined',
      lead: {
        pageOrigine: route.fullPath,
        siteInternetOrigine: origin,
      },
    };

    for (const [key, value] of formData.entries()) {
      if (specialInputs[key] !== undefined) {
        request[key] = specialInputs[key].parse(value);
      } else {
        request[key] = value;
      }
    }

    const raw = jsesc(
      { DonneesFormulaire: request },
      {
        json: true,
      }
    );

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: raw,
    };

    useFetch(api, requestOptions)
      .then((response) => response.data.value)
      .then((response) => {
        if (response.Status !== 'OK') {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch(reject);
  });
};
