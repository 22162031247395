import { pipedriveAdapter } from './adapter';

const sendDeal = async (deal) => {
  const path = '/api/pipedrive/deals';
  const body = { ...deal };

  try {
    const response = await useFetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.status.value === 'success') {
      return await response.data.value;
    }

    throw new Error(response);
  } catch (error) {
    throw new Error(error);
  }
};

export default async ({ formData, withSteps, stepsExtra }) => {
  if (withSteps && stepsExtra) {
    Object.keys(stepsExtra).forEach((extraKey) => {
      formData.append(stepsExtra[extraKey].name, stepsExtra[extraKey].value);
    });
  }

  const deal = await pipedriveAdapter(formData);

  const result = await sendDeal(deal);

  return result;
};
