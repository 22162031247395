import { trackReferral } from '../buyapowa';
import mergeI18n from '~/utils/merge-i18n';
import dataI18n from '~/i18n/messages/fr-fr.mailto.json';
import getMailBody from './getMailBody';

// Sanitiz recipients Data
function serializeRecipients(recipients) {
  if (!recipients) {
    return [];
  }
  const sanizited = recipients.split('|')[1] || recipients;
  return sanizited.split('\n').map((recipient) => ({
    Email: recipient.trim(),
  }));
}

// Get list of recipients
function getRecipients(data, buyapowa) {
  let recipients = data?.type.includes('claim-') ? [{ Email: data.email }] : [];

  if (data?.type.includes('parrainage')) {
    recipients = [{ Email: data.emailFilleul }];
  }

  if (buyapowa) {
    const { broker_id, broker_email } = buyapowa.client_data || {};
    // Brokers exception if brokerId equal one of specificBrokerIds
    const isException = ['81356, 13980, 20245'].indexOf(broker_id) >= 0;
    recipients = [
      { Email: isException ? 'devis.sante@april.com' : broker_email },
    ];
  } else {
    // Use default recipients keys
    const recipientKeys = ['claim', 'recipient', 'contact', 'subject'];
    recipientKeys.forEach((key) => {
      if (data[key]) {
        const recipient = serializeRecipients(data[key]);
        if (recipient.length) {
          recipients.push(...recipient);
        }
      }
    });
  }

  return recipients;
}

const multiCheckboxFields = ['offres', 'contratFilleul'];

export default async ({ formData, cmpid, subject, buyapowa }, i18n) => {
  mergeI18n(i18n, dataI18n);

  if (buyapowa) await trackReferral(buyapowa, formData);

  return new Promise((resolve, reject) => {
    const data = {};
    for (const [key, value] of formData.entries()) {
      if (multiCheckboxFields.includes(key) && !!data[key]) {
        data[key] = `${data[key]} - ${value}`;
      } else {
        data[key] = value;
      }
    }

    const isClaim = data?.type.includes('claim-');

    const recipients = getRecipients(data, buyapowa);

    const subjectKey = !subject && `mailto.${data.type}.subject`;
    const hostname = window.location.hostname;
    const subjectKeyLabel = i18n.te(subjectKey)
      ? i18n.t(subjectKey, [hostname])
      : i18n.t('mailto.default.subject', [hostname]);
    const mailData = {
      From: {
        Email: 'noreply@april.fr',
        Name: 'April',
      },
      ...(isClaim
        ? { To: [recipients[0]], Bcc: recipients.slice(1) }
        : { To: recipients }),
      HTMLPart: getMailBody(data, i18n, buyapowa),
      Subject: subjectKey ? subjectKeyLabel : subject,
      cmpid,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mailData),
    };

    useFetch('/api/mailto', requestOptions)
      .then((response) => {
        if (response?.data?.value?.statusCode === 200) {
          return response.data.value;
        }

        throw new Error(response);
      })
      .then((response) => {
        if (response?.body?.Messages[0]?.Status !== 'success') {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch(reject);
  });
};
